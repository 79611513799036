import React, { FC } from "react";
import style from './Select.module.scss';
import { useTranslation } from "react-i18next";
import { City, Endpoint } from "../../data/citiesDropDownData";
import { Dropdown, Row } from "react-bootstrap";
import group7 from '../../assets/group-7.svg';
import corect from '../../assets/corect.svg';
import ReactTooltip from 'react-tooltip';

export type SelectProps = {
    currentSelectedItem: City | Endpoint,
    selectItemsList: City[] | Endpoint[],
    type: "cities" | "dataType"
    onSelect: Function,
};

export const Select: FC<SelectProps> = ({ selectItemsList, currentSelectedItem, onSelect, type }) => {
    const { t } = useTranslation();

    const infoText = type === "cities" ? t("More cities") : null;
    const dropDownClass = type == "cities" ? style.dropdownBig : style.dropdownSmall;

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle className={`d-flex flex-row align-items-center ${style.dropdownToggle} ${dropDownClass}`} variant="no">
                    <Row className={`d-flex flex-row align-items-center align-content-center`}>
                        {type === "cities" ? <img src={group7} /> : null}
                        {t(currentSelectedItem.name)}
                    </Row>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    {/* Cities list */}
                    {/* @ts-ignore */}
                    {selectItemsList.map(item => (
                        <Dropdown.Item
                            data-tip data-for={item.type === "AreaRainfallRate" ? "area" : "point"}
                            key={item.id}
                            className={`d-flex flex-row align-items-center ${style.dropdownItem} ${dropDownClass}`}
                            onClick={() => onSelect(item)}
                        >
                            {
                                item.name === currentSelectedItem.name &&
                                <img src={corect} className={style.dropDownItemIcon} />
                            }

                            {t(item.name)}
                        </Dropdown.Item>
                    ))}

                    {/* TOOLTIPS */}
                    {type === "dataType"  && 
                        <div style={{ position: 'relative', zIndex: 1, width: 200 }}>
                            <ReactTooltip id="area" place="right" >
                                <div style={{ maxWidth: 200, minWidth: 200 }}>
                                    <span>{t("tooltip area")}</span>
                                </div>
                            </ReactTooltip>
                            <ReactTooltip id="point" place="right">
                                <div style={{ maxWidth: 200, minWidth: 200 }}>
                                    <span>{t("tooltip point")}</span>
                                </div>
                            </ReactTooltip>
                        </div>
                    }

                    {/* Info label */}
                    {
                        infoText &&
                        <Dropdown.Item className={`${style.dropdownItem} ${style.dropdownItemLast}`} disabled={true}>
                            <i>{infoText}</i>
                        </Dropdown.Item>
                    }

                </Dropdown.Menu>

            </Dropdown>

        </>
    );
};

